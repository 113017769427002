<template>
  <section class="project--detail-facility" v-show="facilities && facilities.length > 0">
    <div class="title--text">
      {{ $t('general.unitFacility') }}
    </div>
    <div class="column--wrapper">
      <div
        class="column--content"
        v-for="(facility, index) in facilities"
        :key="'facility-' + index"
      >
        <v-icon>{{ facility.icon }}</v-icon>
        <div class="column--content-right ml-2">
          <div class="column--desc">
            <div class="facility--name">{{ facility.name }}</div>
            <div>{{ facility.amount }} {{ facility.unit }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'project-detail-facility',
  props: ['facilities'],
};
</script>
