<template>
  <section class="project--detail-action" v-if="project">
    <div class="contact--label">
      <img :src="project.developer_photo" :alt="project.developer_name" />
      <span>{{ `${$t('general.partOf')} ${project.project_name}` }}</span>
    </div>
    <router-link v-if="project.links" :to="project.links.detail" class="btn btn-primary">
      <div>{{ $t('general.openProjectPage') }}</div>
    </router-link>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'project-detail-project-info',
  props: ['project'],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({}),
  },
  methods: {},
};
</script>
