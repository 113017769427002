<template>
  <section class="project--detail-similar">
    <div class="d-flex justify-space-between">
      <h2 class="title--text mr-4">
        {{ $t('general.units') }}
      </h2>
      <v-select
        v-model="selectedCategory"
        :items="categories"
        :label="$t('general.filter.unitCategory')"
        item-value="id"
        item-text="name"
        solo
        hide-details
        return-object
      ></v-select>
    </div>
    <div v-if="units && units.length > 0">
      <slick-units :contents="units" :slidesPerRow="3" />
    </div>
  </section>
</template>

<script>
import SlickUnits from '@/components/project-detail/partials/slick-units';
import { mapState } from 'vuex';
export default {
  props: ['units'],
  components: {
    SlickUnits,
  },
  computed: {
    ...mapState({
      categories: (state) => state.v2.unitType.categories,
    }),
    selectedCategory: {
      get() {
        return this.$store.state.v2.unitType.selectedCategory;
      },
      set(value) {
        this.$store.dispatch('v2/unitType/selectCategory', value);
      },
    },
  },
};
</script>
